.Hours {
	.HoursHeading {
		@apply tw-text-gray tw-mb-2 tw-font-normal #{!important};
	}
	.HoursDay, strong {
		@apply tw-font-normal;
	}
	.HoursHolidayHeading {
		@apply tw-font-normal #{!important};
	}
	.HoursHoliday, .HoursHolidayHeading {
		@apply tw-text-red;
	}
	.HoursHolidayText {
		strong
		{
			@apply tw-font-normal #{!important};
		}
	}
}
