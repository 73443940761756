.FavoriteProducts {
	@apply tw-pt-6;

	.FavoriteProductHeadline {
		@apply tw-text-gray tw-mb-5;
	}

	ul {
		@apply tw-flex tw-flex tw-flex-wrap;
		a {
			@apply tw-flex tw-flex-col tw-text-center;
			&:hover {
				@apply tw-outline-blue;
			}
			span {
				@apply tw-text-blue;
			}
		}
		li {
			@apply tw-w-1/2 tw-pr-1 tw-pl-1  tw-mb-10;
		}
	}

	.FavoriteProductsImg {
		img {
			max-height: 150px;
		}
		line-height: 150px;
	}
}

@screen md {
	.FavoriteProducts {
		.FavoriteProductHeadline {
			@apply tw-mb-10 tw-pt-12;
		}
		ul {
			@apply tw-flex-nowrap tw-mb-16;
			li {
				@apply tw-w-1/4 tw-mb-0;
			}
		}
		.FavoriteProductsImg {
			img {
				max-height: 200px;
			}
			line-height: 200px;
		}
	}
}