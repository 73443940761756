.DeliveryCenterDetails {
	@apply tw-pb-8 tw-pt-6;
}

.DeliveryCenterDetailsMessaging {
	@apply tw-text-red;
	p {
		@apply tw-mb-0;
	}
}

@screen md {
	.DeliveryCenterDetails {
		@apply tw-pt-12;
	}
}