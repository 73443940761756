.Contact {
	.ContactPhone {
		@apply tw-mb-3;
		a {
			@apply tw-no-underline tw-text-gray tw-inline-block;
		}
		img {
			@apply tw-inline-block tw-mr-3;
		}
		.ContactCustCare {
			@apply tw-block;
			padding-left: 42px;
		}
	}
	.ContactEmail {
		@apply tw-mb-3;
		> a {
			@apply tw-flex tw-items-center;
		}
		img {
			@apply tw-inline-block tw-mr-3 tw-mt-1;
		}
	}
}

@screen md {
	.Contact {
		.ContactPhone {
			@apply tw-mb-6;
			img { 
				@apply tw-mr-2;
			}
			.ContactCustCare {
				padding-left: 37.5px;
			}
		}
		.ContactEmail {
			@apply tw-mb-6;
			img {
				@apply tw-mt-2;
			}
		}
	}
}