.StoreDetails {
	@apply tw-w-full tw-border-b-gray tw-pb-10 tw-mb-10;
	.StoreDetailsContent {
		.StoreDetailsHeading {
			@apply tw-text-gray tw-mt-2 tw-mb-1;
		}
		.StoreDetailsEmergencyText {
			@apply tw-text-red;
		}
		.StoreDetailsInfo {
			@apply tw-mt-4;
			.StoreDetailsSubhead {
				@apply tw-text-gray tw-mb-2 tw-font-normal #{!important};
			}
		}
	}

}

@screen md {
	.StoreDetails {
		@apply tw-pb-12 tw-mb-16;
		.StoreDetailsContent {
			.StoreDetailsInfo {
				@apply tw-flex;
				.StoreDetailsHours, .StoreDetailsContact {
					@apply tw-w-6/12;
				}
				.StoreDetailsContactText {
					@apply tw-w-10/12;
				}
			}
		}
	}
}