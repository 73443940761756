.CardMedia {
	&.CardMedia--borderTop {
		@apply tw-border-solid tw-border-t tw-border-gray-400 tw-pt-4;
	}
	&.CardMedia--borderTop__sm {
		@apply tw-border-solid tw-border-t tw-border-gray-400 tw-pt-4;
	}
	.CardMediaImage {
		@apply tw-mb-4;
	}
	p {
		@apply tw-mb-4;
	}
	.CardTitle {
		@apply tw-text-gray tw-mb-2;
	}
	.CardMediaList {
		@apply tw-ml-6;
		li {
			@apply tw-list-disc tw-mb-3;
		}
	}
	.CardLinks a, .CardLinks button {
		@apply tw-inline-block;
	}

	a {
		&:focus [class~=ButtonAnchor] {
			@apply tw-text-gray-100
		}
	}
}

@screen md {
	.CardMedia {
		@apply tw-flex;
		&.CardMedia--borderTop__sm {
			@apply tw-border-0;
		}
		.CardMediaImage {
			@apply tw-mb-8;
		}
	}
}
