.AboutUs {
	@apply tw-flex tw-justify-center tw-w-full;
	.AboutUsHeadline, .AboutUsText {
		@apply tw-text-gray tw-mb-3;
	}
	.AboutUsText {
		@apply tw-text-gray tw-pb-2;
	}
}

@screen md {
	.AboutUs {
		.AboutUsText {
			@apply tw-pb-10;
		}
	}
}