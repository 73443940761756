.galleryCaptionContainer {
	@apply tw-max-w-[960px] tw-relative tw-text-center tw-w-full md:tw-text-left;
}

.galleryCaption {
	@apply tw-block tw-mx-2 tw-my-1 md:tw-font-light md:tw-mx-4 md:tw-my-2 md:tw-text-lg;
}

.galleryCaptionFade {
	@apply tw-absolute tw-duration-300 tw-ease-in-out tw-left-0 tw-opacity-0 tw-top-0 tw-transition-opacity;
}

.galleryCaptionCurrent {
	@apply tw-opacity-100;
}

.galleryCaptionPlaceholder {
	@apply tw-invisible;
}
