.gallery {
	@apply tw-flex tw-flex-col tw-items-center;
}

.gallerySliderContainer {
	@apply tw-relative tw-w-full tw-h-full tw-flex;
}

.gallerySlider {
	@apply tw-py-[3px] tw-w-full;
	
	:global .flicking-camera {
		@apply tw-items-center;
	}
}

.gallerySliderArrowsIndented {
	@apply md:tw-mx-[40px];
}

.gallerySliderArrowNext, .gallerySliderArrowPrev {
	@apply tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-w-[35px] tw-z-[2] md:#{'!tw-block'};

	:global .ButtonArrow {
		@apply tw-border-none;
	}
}

.gallerySliderArrowNext {
	@apply tw-right-0;
}

.gallerySliderArrowPrev {
	@apply tw-left-0;
}

.galleryControls {
	@apply tw-flex tw-items-baseline tw-mb-2 tw-mt-4 md:tw-my-2;

	> * {
		@apply tw-px-4;
	}
}

.galleryControlsArrowNext, .galleryControlsArrowPrev {
	@apply tw-hidden md:tw-block;
}

.galleryControlsDotsCounter {
	@apply tw-divide-x tw-flex;

	> * {
		@apply tw-px-5 tw-py-1 md:tw-py-0;
	}
}

.galleryControlsDots {
	@apply tw-flex tw-items-center tw-max-w-[250px];
}

.galleryFullscreen {
	.gallerySlider {
		@apply tw-left-1/2 -tw-translate-x-1/2 tw-w-screen md:tw-left-auto md:tw-translate-x-0 md:tw-w-auto;

		img {
			@apply #{'!tw-max-h-[400px]'};
		}
	}
}
