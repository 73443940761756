.gallerySliderPanelImage {
	@apply tw-duration-300 tw-ease-in-out tw-flex tw-justify-center tw-max-w-full tw-mx-5 tw-transition-opacity sm:#{'!tw-w-full'} md:tw-opacity-30;

	img {
		@apply tw-max-h-[250px] tw-user-drag-none tw-user-select-none sm:tw-w-auto md:tw-max-h-none;
	}
}

.gallerySliderPanelImageCurrent {
	@apply tw-opacity-100;
}

.gallerySliderPanelImageFocusable {
	&:focus, &:hover {
		@apply tw-outline-1 tw-outline-dotted tw-outline-gray-300 tw-outline-offset-2;
	}
}
