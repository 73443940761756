@media screen {
	.image-grid {
		@apply tw-grid tw-gap-1 tw-grid-cols-2 tw-mb-4;
	}
}

@screen md {
	.image-grid {
		@apply tw-gap-4;
		grid-template-columns: 3fr 6fr 3fr;
	}
}
