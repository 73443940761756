.location-details {
	@apply tw-w-full tw-pb-12;

}

.location-address, .location-driving, .location-parking, .location-transit {
	@apply tw-mb-5;
}

.location-headline {
	@apply tw-heading-5 tw-font-normal;
	@screen md {
		@apply tw-mb-2;
	}
}

@screen md {
	.location-address, .location-driving, .location-parking, .location-transit,  {
		@apply tw-mb-8;
	}
	.location-details {
		@apply tw-flex tw-pb-16;

		.location-directions {
			@apply tw-grow;
		}
	}
}
