.galleryFullscreen {
	@apply tw-rounded-full;

	&:focus, &:hover {
		@apply tw-bg-gray;

		.galleryFullscreenImage {
			@apply tw-invert-[1];
		}
	}
}
