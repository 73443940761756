@media screen {
	.event {
		@apply tw-mb-8;
	}

	.calendar-date {
		@apply tw-hidden;
	}

	.calendar-date-text {
		@apply tw-text-center tw-py-4;

		min-height: 84px;
		word-spacing: 9999px;
		border: solid 1px theme('colors.gray.400');
	}
}

@screen md {
	.event {
		@apply tw-mb-0 tw-grid tw-gap-4;

		grid-template-columns: 84px auto;
	}

	.calendar-date {
		@apply tw-block;
	}
}
